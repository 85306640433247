import { render, staticRenderFns } from "./IncidentCollisionReport.vue?vue&type=template&id=3521628e&scoped=true&"
import script from "./IncidentCollisionReport.vue?vue&type=script&lang=ts&"
export * from "./IncidentCollisionReport.vue?vue&type=script&lang=ts&"
import style0 from "./IncidentCollisionReport.vue?vue&type=style&index=0&id=3521628e&prod&scoped=true&lang=scss&"
import style1 from "./IncidentCollisionReport.vue?vue&type=style&index=1&id=3521628e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3521628e",
  null
  
)

export default component.exports