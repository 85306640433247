import { render, staticRenderFns } from "./CustomInlineSvg.vue?vue&type=template&id=7e0161fa&scoped=true&"
import script from "./CustomInlineSvg.vue?vue&type=script&lang=ts&"
export * from "./CustomInlineSvg.vue?vue&type=script&lang=ts&"
import style0 from "./CustomInlineSvg.vue?vue&type=style&index=0&id=7e0161fa&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e0161fa",
  null
  
)

export default component.exports